import { Component } from "react";
import { createChat, getUsers } from "../services/service";
import dayjs from "dayjs";
import { Message } from "iconsax-react";

export default class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            bulkMessageLoading: false,
            users: [],
            sortConfig: {
                key: null,
                direction: 'asc'
            },
            chatMessage: '',
            showChatModal: false,
            selectedUser: null,
        };
    }

    handleSort = (key) => {
        let direction = 'asc';
        if (this.state.sortConfig.key === key && this.state.sortConfig.direction === 'asc') {
            direction = 'desc';
        }

        const sortedUsers = [...this.state.users].sort((a, b) => {
            if (a[key] < b[key]) {
                return direction === 'asc' ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return direction === 'asc' ? 1 : -1;
            }
            return 0;
        });

        this.setState({
            users: sortedUsers,
            sortConfig: { key, direction }
        });
    }

    fetchUsers = async () => {
        const { data } = await getUsers();
        this.setState({ users: data, loading: false });
    };

    sendBulkMessages = async () => {
        const { selectedUser, chatMessage } = this.state;
        if (selectedUser === null || chatMessage === '') {
            return;
        }

        this.setState({ bulkMessageLoading: true });

        await createChat({
            receiver: selectedUser,
            message: chatMessage
        });

        this.setState({ showChatModal: false, bulkMessageLoading: false });
    }

    handleSelectUser = (userId) => {
        const { selectedUser } = this.state;
        if (selectedUser === userId) {
            this.setState({ selectedUser: null });
        } else {
            this.setState({ selectedUser: userId });
        }
    }

    componentDidMount() {
        this.fetchUsers();
    }

    render() {
        const { loading, users, sortConfig, selectedUser, showChatModal } = this.state;

        const getArrow = (key) => {
            if (sortConfig.key === key) {
                return sortConfig.direction === 'asc' ? ' ▲' : ' ▼';
            }
            return '';
        };

        return (
            <main className='px-8 py-8 flex flex-col w-fit'>
                {
                    showChatModal && (
                        <div className='fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center'>
                            <div className='bg-white p-8 rounded-lg shadow-lg'>
                                <h2 className='text-xl font-bold'>Mesaj Gönder</h2>
                                <span className="text-sm text-gray-400 pb-4">Kullanıcıya mesaj gönderilecek</span>
                                <input
                                    type='text'
                                    value={this.state.chatMessage}
                                    onChange={(e) => this.setState({ chatMessage: e.target.value })}
                                    className='border border-slate-200 p-2 w-full my-4' />
                                <div className='flex items-center justify-end gap-x-4'>
                                    {
                                        this.state.bulkMessageLoading ? (
                                            <span className='text-sm font-semibold text-red-500'>Gönderiliyor...</span>
                                        ) : (
                                            <div>
                                                <button
                                                    onClick={this.sendBulkMessages}
                                                    className='bg-black text-white px-4 py-2 rounded-md'>
                                                    Gönder
                                                </button>
                                                <button
                                                    onClick={() => this.setState({ showChatModal: false })}
                                                    className=' text-black px-4 py-2 rounded-md'>
                                                    İptal
                                                </button>
                                            </div>
                                        )
                                    }

                                </div>
                            </div>
                        </div>
                    )
                }

                {
                    loading ? (
                        <div className='flex items-center justify-center h-32'>
                            <span className='text-lg font-bold'>Yükleniyor...</span>
                        </div>
                    ) : (
                        <div className='bg-white rounded-xl pt-4'>
                            <div className="flex flex-row justify-between pr-4 items-center">
                                <span className="px-4 font-semibold">Kullanıcı Listesi</span>
                                {
                                    selectedUser !== null &&
                                    <button
                                        onClick={() => this.setState({ showChatModal: true })}
                                        className='bg-gray-100 text-black border px-2 py-1.5 rounded-md text-sm flex'>
                                        <Message size={20} className='mr-2' />
                                        Toplu Mesaj Gönder
                                    </button>
                                }
                            </div>
                            <table className="mt-4">
                                <thead className='bg-gray-100 text-gray-600'>
                                    <tr>
                                        <th className='border-b border-slate-200 p-4 text-left text-sm font-bold'>

                                        </th>
                                        <th className='border-b border-slate-200 p-4 text-left text-sm font-bold hover:cursor-pointer' onClick={() => this.handleSort('name')}>
                                            Ad Soyad{getArrow('name')}
                                        </th>
                                        <th className='border-b border-slate-200 p-4 text-left text-sm font-bold hover:cursor-pointer' onClick={() => this.handleSort('email')}>
                                            E-posta{getArrow('email')}
                                        </th>
                                        <th className='border-b border-slate-200 p-4 text-left text-sm font-bold hover:cursor-pointer' onClick={() => this.handleSort('phone')}>
                                            Telefon{getArrow('phone')}
                                        </th>
                                        <th className='border-b border-slate-200 p-4 text-left text-sm font-bold hover:cursor-pointer' onClick={() => this.handleSort('is_verified')}>
                                            Doğrulama{getArrow('is_verified')}
                                        </th>
                                        <th className='border-b border-slate-200 p-4 text-left text-sm font-bold hover:cursor-pointer' onClick={() => this.handleSort('active_plan')}>
                                            Üyelik{getArrow('active_plan')}
                                        </th>
                                        <th className='border-b border-slate-200 p-4 text-left text-sm font-bold hover:cursor-pointer' onClick={() => this.handleSort('is_disabled')}>
                                            Durum{getArrow('is_disabled')}
                                        </th>
                                        <th className='border-b border-slate-200 p-4 text-left text-sm font-bold hover:cursor-pointer' onClick={() => this.handleSort('updated_at')}>
                                            Son Aktivite{getArrow('updated_at')}
                                        </th>
                                        <th className='border-b border-slate-200 p-4 text-left text-sm font-bold hover:cursor-pointer' onClick={() => this.handleSort('created_at')}>
                                            Ilk Aktivite{getArrow('created_at')}
                                        </th>
                                        <th className='border-b border-slate-200 p-4 text-left text-sm font-bold hover:cursor-pointer'></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users.map((user) => (
                                        <tr key={user._id}>
                                            <td className='border-b border-slate-200 p-4 text-left text-sm'>
                                                <input
                                                    type="checkbox"
                                                    checked={selectedUser === user._id}
                                                    onChange={() => this.handleSelectUser(user._id)}
                                                />
                                            </td>
                                            <td className='border-b border-slate-200 p-4 text-left text-sm'>
                                                {user.name}
                                            </td>
                                            <td className='border-b border-slate-200 p-4 text-left text-sm'>
                                                {user.email}
                                            </td>
                                            <td className='border-b border-slate-200 p-4 text-left text-sm'>
                                                {user.phone || '-'}
                                            </td>
                                            <td className='border-b border-slate-200 p-4 text-center text-sm'>
                                                {
                                                    user.is_verified ?
                                                        <div className='bg-green-50 text-green-600 border border-green-300 w-min px-4 py-1.5 rounded-full text-xs'>
                                                            Evet
                                                        </div> :
                                                        <div className='bg-gray-50 text-gray-600 border border-gray-200 w-min px-4 py-1.5 rounded-full text-xs'>
                                                            Hayır
                                                        </div>
                                                }
                                            </td>
                                            <td className='border-b border-slate-200 p-4 text-left text-sm'>
                                                {user.active_plan.toUpperCase()}
                                            </td>
                                            <td className='border-b border-slate-200 p-4 text-left text-sm'>
                                                {
                                                    user.is_disabled || user.is_deleted ?

                                                        <div className='bg-red-50 text-red-600 border border-red-300 w-min px-4 py-1.5 rounded-full text-xs'>
                                                            Kapalı
                                                        </div> :
                                                        <div className='bg-green-50 text-green-600 border border-green-300 w-min px-4 py-1.5 rounded-full text-xs'>
                                                            Aktif
                                                        </div>
                                                }
                                            </td>
                                            <td className='border-b border-slate-200 p-4 text-left text-sm'>{
                                                dayjs(user.updated_at).format('DD/MM/YYYY HH:mm')
                                            }
                                            </td>
                                            <td className='border-b border-slate-200 p-4 text-left text-sm'>{
                                                dayjs(user.created_at).format('DD/MM/YYYY HH:mm')
                                            }
                                            </td>
                                            <td className='border-b border-slate-200 p-4 text-left text-sm'>
                                                <a
                                                    href={`/users/${user._id}`}
                                                    className='bg-gray-100 text-black border px-2 py-1.5 rounded-md'>
                                                    Göster
                                                </a>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )
                }
            </main>
        );
    };
}